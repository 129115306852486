/* @tailwind base; is already included in the Phia UI component library, so no need to include it here css */
.container {
    width: 100%;
}
@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}
.display-xxs {
    font-size: var(--fontSize-3xl);
    line-height: var(--lineHeight-2xl);
    font-family: var(--font-serif);
    font-weight: var(--fontWeight-light);
}
.display-xs {
    font-size: var(--fontSize-5xl);
    line-height: var(--lineHeight-4xl);
    font-family: var(--font-serif);
    font-weight: var(--fontWeight-light);
}
.display-sm {
    font-size: var(--fontSize-7xl);
    line-height: var(--lineHeight-6xl);
    font-family: var(--font-serif);
    font-weight: var(--fontWeight-light);
    letter-spacing: -1.76px;
}
.display-md {
    font-size: var(--fontSize-8xl);
    line-height: var(--lineHeight-7xl);
    font-family: var(--font-serif);
    font-weight: var(--fontWeight-light);
    letter-spacing: -2.24px;
}
.display-lg {
    font-size: var(--fontSize-9xl);
    line-height: var(--lineHeight-8xl);
    font-family: var(--font-serif);
    font-weight: var(--fontWeight-light);
    letter-spacing: -3.84px;
}
.heading-sans-xs {
    font-size: var(--fontSize-xl);
    line-height: var(--lineHeight-lg);
    font-family: var(--font-sans);
}
.heading-sans-sm {
    font-size: var(--fontSize-2xl);
    line-height: var(--lineHeight-xl);
    font-family: var(--font-sans);
}
.heading-sans-md {
    font-size: var(--fontSize-3xl);
    line-height: var(--lineHeight-2xl);
    font-family: var(--font-sans);
}
.heading-sans-xl {
    font-size: var(--fontSize-5xl);
    line-height: var(--lineHeight-4xl);
    font-family: var(--font-sans);
}
.heading-serif-xs {
    font-size: var(--fontSize-xl);
    line-height: var(--lineHeight-lg);
    font-family: var(--font-serif);
}
.heading-serif-sm {
    font-size: var(--fontSize-2xl);
    line-height: var(--lineHeight-xl);
    font-family: var(--font-serif);
}
.heading-serif-md {
    font-size: var(--fontSize-3xl);
    line-height: var(--lineHeight-2xl);
    font-family: var(--font-serif);
}
.heading-serif-lg {
    font-size: var(--fontSize-4xl);
    line-height: var(--lineHeight-3xl);
    font-family: var(--font-serif);
}
.heading-serif-xl {
    font-size: var(--fontSize-5xl);
    line-height: var(--lineHeight-4xl);
    font-family: var(--font-serif);
}
.label-xs {
    font-size: var(--fontSize-xs);
    line-height: var(--lineHeight-xs);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-medium);
}
.label-sm {
    font-size: var(--fontSize-sm);
    line-height: var(--lineHeight-xs);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-medium);
}
.label-md {
    font-size: var(--fontSize-md);
    line-height: var(--lineHeight-sm);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-medium);
}
.label-lg {
    font-size: var(--fontSize-lg);
    line-height: var(--lineHeight-md);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-medium);
}
.label-xl {
    font-size: var(--fontSize-xl);
    line-height: var(--lineHeight-lg);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-medium);
}
.label-2xl {
    font-size: var(--fontSize-2xl);
    line-height: var(--lineHeight-xl);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-medium);
}
.para-xs {
    font-size: var(--fontSize-xs);
    line-height: var(--lineHeight-xs);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-normal);
}
.para-sm {
    font-size: var(--fontSize-sm);
    line-height: var(--lineHeight-xs);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-normal);
}
.para-md {
    font-size: var(--fontSize-md);
    line-height: var(--lineHeight-sm);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-normal);
}
.para-lg {
    font-size: var(--fontSize-lg);
    line-height: var(--lineHeight-md);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-normal);
}
.para-xl {
    font-size: var(--fontSize-xl);
    line-height: var(--lineHeight-lg);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-normal);
}
.para-ed-sm {
    font-size: var(--fontSize-sm);
    line-height: var(--lineHeight-sm);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-normal);
}
.para-ed-md {
    font-size: var(--fontSize-md);
    line-height: var(--lineHeight-lg);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-normal);
}
.pointer-events-none {
    pointer-events: none;
}
.pointer-events-auto {
    pointer-events: auto;
}
.visible {
    visibility: visible;
}
.invisible {
    visibility: hidden;
}
.static {
    position: static;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.sticky {
    position: sticky;
}
.inset-0 {
    inset: 0px;
}
.-bottom-1 {
    bottom: -0.25rem;
}
.-bottom-2 {
    bottom: -0.5rem;
}
.-bottom-4 {
    bottom: -1rem;
}
.-bottom-8 {
    bottom: -2rem;
}
.-bottom-\[11px\] {
    bottom: -11px;
}
.-bottom-\[35px\] {
    bottom: -35px;
}
.-bottom-\[7\.5px\] {
    bottom: -7.5px;
}
.-left-1\.5 {
    left: -0.375rem;
}
.-left-16 {
    left: -4rem;
}
.-left-\[120px\] {
    left: -120px;
}
.-left-\[14px\] {
    left: -14px;
}
.-left-\[34px\] {
    left: -34px;
}
.-right-1\.5 {
    right: -0.375rem;
}
.-right-12 {
    right: -3rem;
}
.-right-\[35px\] {
    right: -35px;
}
.-top-2 {
    top: -0.5rem;
}
.-top-20 {
    top: -5rem;
}
.-top-\[11px\] {
    top: -11px;
}
.-top-\[12px\] {
    top: -12px;
}
.-top-\[17px\] {
    top: -17px;
}
.-top-\[40px\] {
    top: -40px;
}
.-top-\[60px\] {
    top: -60px;
}
.bottom-0 {
    bottom: 0px;
}
.bottom-1 {
    bottom: 0.25rem;
}
.bottom-2 {
    bottom: 0.5rem;
}
.bottom-3 {
    bottom: 0.75rem;
}
.bottom-32 {
    bottom: 8rem;
}
.bottom-4 {
    bottom: 1rem;
}
.bottom-\[10px\] {
    bottom: 10px;
}
.bottom-\[85px\] {
    bottom: 85px;
}
.bottom-lg {
    bottom: 16px;
}
.left-0 {
    left: 0px;
}
.left-0\.5 {
    left: 0.125rem;
}
.left-1 {
    left: 0.25rem;
}
.left-1\/2 {
    left: 50%;
}
.left-10 {
    left: 2.5rem;
}
.left-12 {
    left: 3rem;
}
.left-2 {
    left: 0.5rem;
}
.left-28 {
    left: 7rem;
}
.left-3 {
    left: 0.75rem;
}
.left-4 {
    left: 1rem;
}
.left-5 {
    left: 1.25rem;
}
.left-6 {
    left: 1.5rem;
}
.left-8 {
    left: 2rem;
}
.left-\[-8px\] {
    left: -8px;
}
.left-\[16px\] {
    left: 16px;
}
.left-\[25\%\] {
    left: 25%;
}
.left-xl {
    left: 24px;
}
.right-0 {
    right: 0px;
}
.right-1 {
    right: 0.25rem;
}
.right-11 {
    right: 2.75rem;
}
.right-2 {
    right: 0.5rem;
}
.right-3 {
    right: 0.75rem;
}
.right-4 {
    right: 1rem;
}
.right-5 {
    right: 1.25rem;
}
.right-6 {
    right: 1.5rem;
}
.right-8 {
    right: 2rem;
}
.right-\[-23px\] {
    right: -23px;
}
.right-\[-2px\] {
    right: -2px;
}
.right-\[30px\] {
    right: 30px;
}
.right-\[45px\] {
    right: 45px;
}
.right-full {
    right: 100%;
}
.right-md {
    right: 12px;
}
.right-xl {
    right: 24px;
}
.top-0 {
    top: 0px;
}
.top-0\.5 {
    top: 0.125rem;
}
.top-1\/2 {
    top: 50%;
}
.top-14 {
    top: 3.5rem;
}
.top-2 {
    top: 0.5rem;
}
.top-3 {
    top: 0.75rem;
}
.top-4 {
    top: 1rem;
}
.top-5 {
    top: 1.25rem;
}
.top-6 {
    top: 1.5rem;
}
.top-8 {
    top: 2rem;
}
.top-\[-10px\] {
    top: -10px;
}
.top-\[-6px\] {
    top: -6px;
}
.top-\[0px\] {
    top: 0px;
}
.top-\[10\%\] {
    top: 10%;
}
.top-\[150\%\] {
    top: 150%;
}
.top-\[56px\] {
    top: 56px;
}
.top-auto {
    top: auto;
}
.top-md {
    top: 12px;
}
.top-xl {
    top: 24px;
}
.-z-10 {
    z-index: -10;
}
.z-10 {
    z-index: 10;
}
.z-20 {
    z-index: 20;
}
.z-30 {
    z-index: 30;
}
.z-50 {
    z-index: 50;
}
.z-\[50\] {
    z-index: 50;
}
.z-\[92\] {
    z-index: 92;
}
.z-\[999\] {
    z-index: 999;
}
.z-\[99\] {
    z-index: 99;
}
.m-0 {
    margin: 0px;
}
.m-10 {
    margin: 2.5rem;
}
.m-3 {
    margin: 0.75rem;
}
.m-auto {
    margin: auto;
}
.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.mx-lg {
    margin-left: 16px;
    margin-right: 16px;
}
.my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
}
.-ml-4 {
    margin-left: -1rem;
}
.-mr-2 {
    margin-right: -0.5rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-20 {
    margin-bottom: 5rem;
}
.mb-3 {
    margin-bottom: 0.75rem;
}
.mb-4 {
    margin-bottom: 1rem;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.mb-\[2\.5rem\] {
    margin-bottom: 2.5rem;
}
.mb-\[33px\] {
    margin-bottom: 33px;
}
.mb-md {
    margin-bottom: 12px;
}
.mb-sm {
    margin-bottom: var(--spacing-small);
}
.mb-xl {
    margin-bottom: 24px;
}
.mb-xs {
    margin-bottom: var(--spacing-xsmall);
}
.ml-0 {
    margin-left: 0px;
}
.ml-1 {
    margin-left: 0.25rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-3 {
    margin-left: 0.75rem;
}
.ml-5 {
    margin-left: 1.25rem;
}
.ml-\[400px\] {
    margin-left: 400px;
}
.ml-auto {
    margin-left: auto;
}
.ml-lg {
    margin-left: 16px;
}
.ml-sm {
    margin-left: var(--spacing-small);
}
.mr-1 {
    margin-right: 0.25rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mr-3 {
    margin-right: 0.75rem;
}
.mr-4 {
    margin-right: 1rem;
}
.mr-auto {
    margin-right: auto;
}
.mr-sm {
    margin-right: var(--spacing-small);
}
.mr-xs {
    margin-right: var(--spacing-xsmall);
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mt-16 {
    margin-top: 4rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-20 {
    margin-top: 5rem;
}
.mt-2xl {
    margin-top: 32px;
}
.mt-3 {
    margin-top: 0.75rem;
}
.mt-3xl {
    margin-top: 40px;
}
.mt-4 {
    margin-top: 1rem;
}
.mt-6 {
    margin-top: 1.5rem;
}
.mt-8 {
    margin-top: 2rem;
}
.mt-\[164px\] {
    margin-top: 164px;
}
.mt-\[22px\] {
    margin-top: 22px;
}
.mt-\[3\.75rem\] {
    margin-top: 3.75rem;
}
.mt-\[37px\] {
    margin-top: 37px;
}
.mt-\[3rem\] {
    margin-top: 3rem;
}
.mt-\[43px\] {
    margin-top: 43px;
}
.mt-\[52px\] {
    margin-top: 52px;
}
.mt-\[8px\] {
    margin-top: 8px;
}
.mt-xl {
    margin-top: 24px;
}
.mt-xs {
    margin-top: var(--spacing-xsmall);
}
.box-border {
    box-sizing: border-box;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.flex {
    display: flex;
}
.inline-flex {
    display: inline-flex;
}
.grid {
    display: grid;
}
.hidden {
    display: none;
}
.aspect-\[1\/1\.2\] {
    aspect-ratio: 1/1.2;
}
.h-0 {
    height: 0px;
}
.h-1 {
    height: 0.25rem;
}
.h-10 {
    height: 2.5rem;
}
.h-11 {
    height: 2.75rem;
}
.h-12 {
    height: 3rem;
}
.h-14 {
    height: 3.5rem;
}
.h-2 {
    height: 0.5rem;
}
.h-3 {
    height: 0.75rem;
}
.h-4 {
    height: 1rem;
}
.h-5 {
    height: 1.25rem;
}
.h-6 {
    height: 1.5rem;
}
.h-60 {
    height: 15rem;
}
.h-7 {
    height: 1.75rem;
}
.h-72 {
    height: 18rem;
}
.h-8 {
    height: 2rem;
}
.h-\[1\.75rem\] {
    height: 1.75rem;
}
.h-\[108px\] {
    height: 108px;
}
.h-\[110px\] {
    height: 110px;
}
.h-\[115px\] {
    height: 115px;
}
.h-\[124px\] {
    height: 124px;
}
.h-\[150px\] {
    height: 150px;
}
.h-\[156px\] {
    height: 156px;
}
.h-\[160px\] {
    height: 160px;
}
.h-\[166px\] {
    height: 166px;
}
.h-\[180px\] {
    height: 180px;
}
.h-\[200px\] {
    height: 200px;
}
.h-\[220px\] {
    height: 220px;
}
.h-\[22px\] {
    height: 22px;
}
.h-\[230px\] {
    height: 230px;
}
.h-\[24px\] {
    height: 24px;
}
.h-\[255px\] {
    height: 255px;
}
.h-\[280px\] {
    height: 280px;
}
.h-\[282px\] {
    height: 282px;
}
.h-\[28px\] {
    height: 28px;
}
.h-\[3\.5rem\] {
    height: 3.5rem;
}
.h-\[305px\] {
    height: 305px;
}
.h-\[320px\] {
    height: 320px;
}
.h-\[32px\] {
    height: 32px;
}
.h-\[348px\] {
    height: 348px;
}
.h-\[34px\] {
    height: 34px;
}
.h-\[36px\] {
    height: 36px;
}
.h-\[374px\] {
    height: 374px;
}
.h-\[375px\] {
    height: 375px;
}
.h-\[385px\] {
    height: 385px;
}
.h-\[399px\] {
    height: 399px;
}
.h-\[40px\] {
    height: 40px;
}
.h-\[425px\] {
    height: 425px;
}
.h-\[434px\] {
    height: 434px;
}
.h-\[44px\] {
    height: 44px;
}
.h-\[50px\] {
    height: 50px;
}
.h-\[512px\] {
    height: 512px;
}
.h-\[52px\] {
    height: 52px;
}
.h-\[56px\] {
    height: 56px;
}
.h-\[600px\] {
    height: 600px;
}
.h-\[60vh\] {
    height: 60vh;
}
.h-\[680px\] {
    height: 680px;
}
.h-\[6px\] {
    height: 6px;
}
.h-\[calc\(100vh\)\] {
    height: calc(100vh);
}
.h-\[calc\(100vh_-_7\.5rem\)\] {
    height: calc(100vh - 7.5rem);
}
.h-auto {
    height: auto;
}
.h-dvh {
    height: 100dvh;
}
.h-fit {
    height: fit-content;
}
.h-full {
    height: 100%;
}
.h-min {
    height: min-content;
}
.h-screen {
    height: 100vh;
}
.h-svh {
    height: 100svh;
}
.max-h-\[264px\] {
    max-height: 264px;
}
.max-h-\[375px\] {
    max-height: 375px;
}
.max-h-\[500px\] {
    max-height: 500px;
}
.max-h-\[800px\] {
    max-height: 800px;
}
.max-h-\[95\%\] {
    max-height: 95%;
}
.min-h-10 {
    min-height: 2.5rem;
}
.min-h-28 {
    min-height: 7rem;
}
.min-h-40 {
    min-height: 10rem;
}
.min-h-7 {
    min-height: 1.75rem;
}
.min-h-\[215px\] {
    min-height: 215px;
}
.min-h-\[3\.375rem\] {
    min-height: 3.375rem;
}
.min-h-\[300px\] {
    min-height: 300px;
}
.min-h-\[342px\] {
    min-height: 342px;
}
.min-h-\[44px\] {
    min-height: 44px;
}
.min-h-\[500px\] {
    min-height: 500px;
}
.min-h-\[528px\] {
    min-height: 528px;
}
.min-h-\[calc\(100vh_-_200px\)\] {
    min-height: calc(100vh - 200px);
}
.min-h-\[unset\] {
    min-height: unset;
}
.min-h-screen {
    min-height: 100vh;
}
.w-0 {
    width: 0px;
}
.w-1\/2 {
    width: 50%;
}
.w-10 {
    width: 2.5rem;
}
.w-12 {
    width: 3rem;
}
.w-2 {
    width: 0.5rem;
}
.w-3 {
    width: 0.75rem;
}
.w-4 {
    width: 1rem;
}
.w-48 {
    width: 12rem;
}
.w-5 {
    width: 1.25rem;
}
.w-56 {
    width: 14rem;
}
.w-60 {
    width: 15rem;
}
.w-7 {
    width: 1.75rem;
}
.w-8 {
    width: 2rem;
}
.w-\[1\.75rem\] {
    width: 1.75rem;
}
.w-\[100px\] {
    width: 100px;
}
.w-\[110px\] {
    width: 110px;
}
.w-\[120px\] {
    width: 120px;
}
.w-\[122px\] {
    width: 122px;
}
.w-\[145px\] {
    width: 145px;
}
.w-\[150px\] {
    width: 150px;
}
.w-\[160px\] {
    width: 160px;
}
.w-\[168px\] {
    width: 168px;
}
.w-\[175px\] {
    width: 175px;
}
.w-\[180px\] {
    width: 180px;
}
.w-\[190px\] {
    width: 190px;
}
.w-\[1px\] {
    width: 1px;
}
.w-\[200px\] {
    width: 200px;
}
.w-\[240px\] {
    width: 240px;
}
.w-\[255px\] {
    width: 255px;
}
.w-\[256px\] {
    width: 256px;
}
.w-\[26px\] {
    width: 26px;
}
.w-\[280px\] {
    width: 280px;
}
.w-\[290px\] {
    width: 290px;
}
.w-\[300px\] {
    width: 300px;
}
.w-\[320px\] {
    width: 320px;
}
.w-\[32px\] {
    width: 32px;
}
.w-\[33px\] {
    width: 33px;
}
.w-\[350px\] {
    width: 350px;
}
.w-\[36px\] {
    width: 36px;
}
.w-\[370px\] {
    width: 370px;
}
.w-\[393px\] {
    width: 393px;
}
.w-\[450px\] {
    width: 450px;
}
.w-\[50px\] {
    width: 50px;
}
.w-\[550px\] {
    width: 550px;
}
.w-\[580px\] {
    width: 580px;
}
.w-\[60\%\] {
    width: 60%;
}
.w-\[670px\] {
    width: 670px;
}
.w-\[80\%\] {
    width: 80%;
}
.w-\[80px\] {
    width: 80px;
}
.w-\[85\%\] {
    width: 85%;
}
.w-\[94\%\] {
    width: 94%;
}
.w-\[calc\(100vw-_-1\.5rem\)\] {
    width: calc(100vw - -1.5rem);
}
.w-\[calc\(100vw_-_1\.5rem\)\] {
    width: calc(100vw - 1.5rem);
}
.w-auto {
    width: auto;
}
.w-fit {
    width: fit-content;
}
.w-full {
    width: 100%;
}
.w-max {
    width: max-content;
}
.w-screen {
    width: 100vw;
}
.w-svw {
    width: 100svw;
}
.min-w-0 {
    min-width: 0px;
}
.min-w-4 {
    min-width: 1rem;
}
.min-w-\[105px\] {
    min-width: 105px;
}
.min-w-\[140px\] {
    min-width: 140px;
}
.min-w-\[328px\] {
    min-width: 328px;
}
.min-w-\[unset\] {
    min-width: unset;
}
.min-w-full {
    min-width: 100%;
}
.max-w-0 {
    max-width: 0px;
}
.max-w-20 {
    max-width: 5rem;
}
.max-w-\[1000px\] {
    max-width: 1000px;
}
.max-w-\[1100px\] {
    max-width: 1100px;
}
.max-w-\[110px\] {
    max-width: 110px;
}
.max-w-\[1200px\] {
    max-width: 1200px;
}
.max-w-\[120px\] {
    max-width: 120px;
}
.max-w-\[126px\] {
    max-width: 126px;
}
.max-w-\[132px\] {
    max-width: 132px;
}
.max-w-\[1400px\] {
    max-width: 1400px;
}
.max-w-\[140px\] {
    max-width: 140px;
}
.max-w-\[1440px\] {
    max-width: 1440px;
}
.max-w-\[180px\] {
    max-width: 180px;
}
.max-w-\[200px\] {
    max-width: 200px;
}
.max-w-\[218px\] {
    max-width: 218px;
}
.max-w-\[250px\] {
    max-width: 250px;
}
.max-w-\[255px\] {
    max-width: 255px;
}
.max-w-\[264px\] {
    max-width: 264px;
}
.max-w-\[280px\] {
    max-width: 280px;
}
.max-w-\[290px\] {
    max-width: 290px;
}
.max-w-\[300px\] {
    max-width: 300px;
}
.max-w-\[310px\] {
    max-width: 310px;
}
.max-w-\[312px\] {
    max-width: 312px;
}
.max-w-\[320px\] {
    max-width: 320px;
}
.max-w-\[335px\] {
    max-width: 335px;
}
.max-w-\[340px\] {
    max-width: 340px;
}
.max-w-\[342px\] {
    max-width: 342px;
}
.max-w-\[350px\] {
    max-width: 350px;
}
.max-w-\[390px\] {
    max-width: 390px;
}
.max-w-\[400px\] {
    max-width: 400px;
}
.max-w-\[500px\] {
    max-width: 500px;
}
.max-w-\[550px\] {
    max-width: 550px;
}
.max-w-\[570px\] {
    max-width: 570px;
}
.max-w-\[600px\] {
    max-width: 600px;
}
.max-w-\[700px\] {
    max-width: 700px;
}
.max-w-\[720px\] {
    max-width: 720px;
}
.max-w-\[900px\] {
    max-width: 900px;
}
.max-w-\[calc\(100\%_-_50px\)\] {
    max-width: calc(100% - 50px);
}
.max-w-full {
    max-width: 100%;
}
.max-w-none {
    max-width: none;
}
.flex-1 {
    flex: 1 1 0%;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.grow {
    flex-grow: 1;
}
.grow-0 {
    flex-grow: 0;
}
.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-x-4 {
    --tw-translate-x: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-x-\[25\%\] {
    --tw-translate-x: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-2 {
    --tw-translate-x: 0.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-24 {
    --tw-translate-x: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-3 {
    --tw-translate-x: 0.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[2\.5rem\] {
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-180 {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-2 {
    --tw-rotate: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-\[15deg\] {
    --tw-rotate: -15deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-2 {
    --tw-rotate: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-6 {
    --tw-rotate: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[-7deg\] {
    --tw-rotate: -7deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[18deg\] {
    --tw-rotate: 18deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[270deg\] {
    --tw-rotate: 270deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes bounce {
    0%, 100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8,0,1,1);
    }
    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0,0,0.2,1);
    }
}
.animate-bounce {
    animation: bounce 1s infinite;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
    from {
        transform: rotate(0deg);
    }
}
.animate-spin {
    animation: spin 5s linear infinite;
}
.cursor-default {
    cursor: default;
}
.cursor-pointer {
    cursor: pointer;
}
.select-none {
    user-select: none;
}
.resize-none {
    resize: none;
}
.list-disc {
    list-style-type: disc;
}
.auto-cols-auto {
    grid-auto-columns: auto;
}
.auto-cols-max {
    grid-auto-columns: max-content;
}
.grid-flow-col {
    grid-auto-flow: column;
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-\[120px_auto\] {
    grid-template-columns: 120px auto;
}
.grid-cols-\[1fr_126px\] {
    grid-template-columns: 1fr 126px;
}
.grid-cols-\[1fr_auto\] {
    grid-template-columns: 1fr auto;
}
.grid-cols-\[56px_1fr\] {
    grid-template-columns: 56px 1fr;
}
.grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
}
.grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.flex-col-reverse {
    flex-direction: column-reverse;
}
.flex-wrap {
    flex-wrap: wrap;
}
.place-items-center {
    place-items: center;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.gap-0 {
    gap: 0px;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-12 {
    gap: 3rem;
}
.gap-14 {
    gap: 3.5rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-2\.5 {
    gap: 0.625rem;
}
.gap-20 {
    gap: 5rem;
}
.gap-24 {
    gap: 6rem;
}
.gap-2xl {
    gap: 32px;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-3xl {
    gap: 40px;
}
.gap-4 {
    gap: 1rem;
}
.gap-5 {
    gap: 1.25rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-7 {
    gap: 1.75rem;
}
.gap-8 {
    gap: 2rem;
}
.gap-9 {
    gap: 2.25rem;
}
.gap-\[10px\] {
    gap: 10px;
}
.gap-\[12px\] {
    gap: 12px;
}
.gap-\[22px\] {
    gap: 22px;
}
.gap-\[3\.6px\] {
    gap: 3.6px;
}
.gap-lg {
    gap: 16px;
}
.gap-md {
    gap: 12px;
}
.gap-sm {
    gap: var(--spacing-small);
}
.gap-xl {
    gap: 24px;
}
.gap-xs {
    gap: var(--spacing-xsmall);
}
.gap-x-10 {
    column-gap: 2.5rem;
}
.gap-x-4 {
    column-gap: 1rem;
}
.gap-y-2xl {
    row-gap: 32px;
}
.gap-y-4 {
    row-gap: 1rem;
}
.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.self-end {
    align-self: flex-end;
}
.self-center {
    align-self: center;
}
.justify-self-end {
    justify-self: end;
}
.justify-self-center {
    justify-self: center;
}
.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-clip {
    overflow: clip;
}
.overflow-x-auto {
    overflow-x: auto;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.overflow-y-clip {
    overflow-y: clip;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.overflow-y-scroll {
    overflow-y: scroll;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.whitespace-pre-wrap {
    white-space: pre-wrap;
}
.text-wrap {
    text-wrap: wrap;
}
.text-nowrap {
    text-wrap: nowrap;
}
.text-pretty {
    text-wrap: pretty;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-3xl {
    border-radius: 1.5rem;
}
.rounded-\[1\.25rem\] {
    border-radius: 1.25rem;
}
.rounded-\[100px\] {
    border-radius: 100px;
}
.rounded-\[12px\] {
    border-radius: 12px;
}
.rounded-\[16px\] {
    border-radius: 16px;
}
.rounded-\[22px\] {
    border-radius: 22px;
}
.rounded-\[4px\] {
    border-radius: 4px;
}
.rounded-\[5px\] {
    border-radius: 5px;
}
.rounded-\[6px\] {
    border-radius: 6px;
}
.rounded-\[999px\] {
    border-radius: 999px;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: var(--radiiLarge-container);
}
.rounded-md {
    border-radius: var(--radiiMediumContainer);
}
.rounded-none {
    border-radius: 0px;
}
.rounded-sm {
    border-radius: var(--radiiSmallContainer);
}
.rounded-xl {
    border-radius: var(--radiiModal);
}
.rounded-xs {
    border-radius: var(--radiiAction);
}
.rounded-b-\[22px\] {
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 22px;
}
.rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.rounded-t-md {
    border-top-left-radius: var(--radiiMediumContainer);
    border-top-right-radius: var(--radiiMediumContainer);
}
.rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.rounded-t-sm {
    border-top-left-radius: var(--radiiSmallContainer);
    border-top-right-radius: var(--radiiSmallContainer);
}
.rounded-bl-\[19\.2px\] {
    border-bottom-left-radius: 19.2px;
}
.rounded-tl-\[19\.2px\] {
    border-top-left-radius: 19.2px;
}
.border {
    border-width: 1px;
}
.border-2 {
    border-width: 2px;
}
.border-\[0\.5px\] {
    border-width: 0.5px;
}
.border-\[1px\] {
    border-width: 1px;
}
.border-b {
    border-bottom-width: 1px;
}
.border-b-8 {
    border-bottom-width: 8px;
}
.border-b-\[1px\] {
    border-bottom-width: 1px;
}
.border-l {
    border-left-width: 1px;
}
.border-l-8 {
    border-left-width: 8px;
}
.border-r {
    border-right-width: 1px;
}
.border-r-8 {
    border-right-width: 8px;
}
.border-solid {
    border-style: solid;
}
.border-dashed {
    border-style: dashed;
}
.border-none {
    border-style: none;
}
.border-\[rgba\(255\2c 255\2c 255\2c 0\.04\)\] {
    border-color: rgba(255,255,255,0.04);
}
.border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}
.border-border-opaque {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.border-border-transparent {
    border-color: rgba(0, 0, 0, 0.08);
}
.border-cn-accent {
    border-color: var(--color-blue750);
}
.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
.border-overlay-inverse100 {
    border-color: var(--Overlay-Inverse100);
}
.border-transparent {
    border-color: transparent;
}
.border-b-border-opaque {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.border-b-cn-primary {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}
.border-l-cn-accent {
    border-left-color: var(--color-blue750);
}
.border-l-transparent {
    border-left-color: transparent;
}
.border-r-cn-accent {
    border-right-color: var(--color-blue750);
}
.border-r-transparent {
    border-right-color: transparent;
}
.\!bg-transparent {
    background-color: transparent !important;
}
.bg-\[\#141414\] {
    --tw-bg-opacity: 1;
    background-color: rgb(20 20 20 / var(--tw-bg-opacity, 1));
}
.bg-\[\#1F7045\] {
    --tw-bg-opacity: 1;
    background-color: rgb(31 112 69 / var(--tw-bg-opacity, 1));
}
.bg-\[\#EBECCA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(235 236 202 / var(--tw-bg-opacity, 1));
}
.bg-\[rgb\(230_237_253\)\] {
    --tw-bg-opacity: 1;
    background-color: rgb(230 237 253 / var(--tw-bg-opacity, 1));
}
.bg-\[rgb\(234\2c 241\2c 237\)\] {
    --tw-bg-opacity: 1;
    background-color: rgb(234 241 237 / var(--tw-bg-opacity, 1));
}
.bg-\[rgba\(255\2c 255\2c 255\2c 0\.7\)\] {
    background-color: rgba(255,255,255,0.7);
}
.bg-\[rgba\(8\2c 75\2c 231\2c 0\.10\)\] {
    background-color: rgba(8,75,231,0.10);
}
.bg-\[rgba\(8\2c 75\2c 231\2c 0\.15\)\] {
    background-color: rgba(8,75,231,0.15);
}
.bg-bg-accent {
    background-color: var(--color-blue750);
}
.bg-bg-neutral {
    --tw-bg-opacity: 1;
    background-color: rgb(244 248 251 / var(--tw-bg-opacity, 1));
}
.bg-bg-positive {
    --tw-bg-opacity: 1;
    background-color: rgb(225 238 206 / var(--tw-bg-opacity, 1));
}
.bg-bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-bg-secondary {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity, 1));
}
.bg-bg-tertiary {
    --tw-bg-opacity: 1;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity, 1));
}
.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}
.bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}
.bg-cn-negative {
    --tw-bg-opacity: 1;
    background-color: rgb(228 79 82 / var(--tw-bg-opacity, 1));
}
.bg-cn-positive {
    --tw-bg-opacity: 1;
    background-color: rgb(70 100 45 / var(--tw-bg-opacity, 1));
}
.bg-cn-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}
.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}
.bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}
.bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}
.bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}
.bg-overlay-200 {
    background-color: rgba(255, 255, 255, 0.16);
}
.bg-overlay-950 {
    background-color: rgba(255, 255, 255, 0.90);
}
.bg-overlay-inverse100 {
    background-color: var(--Overlay-Inverse100);
}
.bg-overlay-inverse200 {
    background-color: var(--Overlay-Inverse200);
}
.bg-transparent {
    background-color: transparent;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-white\/40 {
    background-color: rgb(255 255 255 / 0.4);
}
.bg-opacity-0 {
    --tw-bg-opacity: 0;
}
.bg-opacity-10 {
    --tw-bg-opacity: 0.1;
}
.bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}
.bg-opacity-30 {
    --tw-bg-opacity: 0.3;
}
.bg-opacity-40 {
    --tw-bg-opacity: 0.4;
}
.bg-opacity-45 {
    --tw-bg-opacity: 0.45;
}
.bg-opacity-50 {
    --tw-bg-opacity: 0.5;
}
.bg-opacity-70 {
    --tw-bg-opacity: 0.7;
}
.bg-opacity-75 {
    --tw-bg-opacity: 0.75;
}
.bg-opacity-90 {
    --tw-bg-opacity: 0.9;
}
.bg-\[linear-gradient\(180deg\2c _\#fff_0\%\2c _rgba\(0\2c 0\2c 0\2c 0\.00\)_100\%\)\] {
    background-image: linear-gradient(180deg, #fff 0%, rgba(0,0,0,0.00) 100%);
}
.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-\[\#1F70451A\] {
    --tw-gradient-from: #1F70451A var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(31 112 69 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[\#6390F8\] {
    --tw-gradient-from: #6390F8 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(99 144 248 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[\#eaf2ff\] {
    --tw-gradient-from: #eaf2ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(234 242 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-bg-negative {
    --tw-gradient-from: #FDD2D2 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(253 210 210 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-bg-neutral {
    --tw-gradient-from: #F4F8FB var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(244 248 251 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-bg-positive {
    --tw-gradient-from: #E1EECE var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(225 238 206 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-bg-warning {
    --tw-gradient-from: #F9EDDF var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(249 237 223 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-overlay-inverse100 {
    --tw-gradient-from: var(--Overlay-Inverse100) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-transparent {
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-white\/10 {
    --tw-gradient-from: rgb(255 255 255 / 0.1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-white\/80 {
    --tw-gradient-from: rgb(255 255 255 / 0.8) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-\[\#084BE7\] {
    --tw-gradient-to: #084BE7 var(--tw-gradient-to-position);
}
.to-\[\#ffffff\] {
    --tw-gradient-to: #ffffff var(--tw-gradient-to-position);
}
.to-bg-secondary {
    --tw-gradient-to: #F8F8F8 var(--tw-gradient-to-position);
}
.to-white\/10 {
    --tw-gradient-to: rgb(255 255 255 / 0.1) var(--tw-gradient-to-position);
}
.to-white\/80 {
    --tw-gradient-to: rgb(255 255 255 / 0.8) var(--tw-gradient-to-position);
}
.bg-cover {
    background-size: cover;
}
.bg-no-repeat {
    background-repeat: no-repeat;
}
.fill-black {
    fill: #000;
}
.object-contain {
    object-fit: contain;
}
.object-cover {
    object-fit: cover;
}
.object-bottom {
    object-position: bottom;
}
.object-center {
    object-position: center;
}
.object-left {
    object-position: left;
}
.object-top {
    object-position: top;
}
.p-0 {
    padding: 0px;
}
.p-1\.5 {
    padding: 0.375rem;
}
.p-10 {
    padding: 2.5rem;
}
.p-12 {
    padding: 3rem;
}
.p-14 {
    padding: 3.5rem;
}
.p-16 {
    padding: 4rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-2xl {
    padding: 32px;
}
.p-3 {
    padding: 0.75rem;
}
.p-4 {
    padding: 1rem;
}
.p-5 {
    padding: 1.25rem;
}
.p-6 {
    padding: 1.5rem;
}
.p-8 {
    padding: 2rem;
}
.p-\[12px\] {
    padding: 12px;
}
.p-lg {
    padding: 16px;
}
.p-md {
    padding: 12px;
}
.p-sm {
    padding: var(--spacing-small);
}
.p-xl {
    padding: 24px;
}
.px-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-2xl {
    padding-left: 32px;
    padding-right: 32px;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
.px-\[10\.18px\] {
    padding-left: 10.18px;
    padding-right: 10.18px;
}
.px-\[14\.4px\] {
    padding-left: 14.4px;
    padding-right: 14.4px;
}
.px-\[18px\] {
    padding-left: 18px;
    padding-right: 18px;
}
.px-\[34px\] {
    padding-left: 34px;
    padding-right: 34px;
}
.px-lg {
    padding-left: 16px;
    padding-right: 16px;
}
.px-md {
    padding-left: 12px;
    padding-right: 12px;
}
.px-sm {
    padding-left: var(--spacing-small);
    padding-right: var(--spacing-small);
}
.px-xl {
    padding-left: 24px;
    padding-right: 24px;
}
.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}
.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
}
.py-3xl {
    padding-top: 40px;
    padding-bottom: 40px;
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-\[13\.2px\] {
    padding-top: 13.2px;
    padding-bottom: 13.2px;
}
.py-\[2px\] {
    padding-top: 2px;
    padding-bottom: 2px;
}
.py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
}
.py-lg {
    padding-top: 16px;
    padding-bottom: 16px;
}
.py-md {
    padding-top: 12px;
    padding-bottom: 12px;
}
.py-sm {
    padding-top: var(--spacing-small);
    padding-bottom: var(--spacing-small);
}
.py-xl {
    padding-top: 24px;
    padding-bottom: 24px;
}
.pb-10 {
    padding-bottom: 2.5rem;
}
.pb-20 {
    padding-bottom: 5rem;
}
.pb-2xl {
    padding-bottom: 32px;
}
.pb-4 {
    padding-bottom: 1rem;
}
.pb-6 {
    padding-bottom: 1.5rem;
}
.pb-\[0px\] {
    padding-bottom: 0px;
}
.pb-\[10rem\] {
    padding-bottom: 10rem;
}
.pb-\[2\.55px\] {
    padding-bottom: 2.55px;
}
.pb-\[92px\] {
    padding-bottom: 92px;
}
.pb-lg {
    padding-bottom: 16px;
}
.pb-md {
    padding-bottom: 12px;
}
.pb-sm {
    padding-bottom: var(--spacing-small);
}
.pb-xl {
    padding-bottom: 24px;
}
.pl-12 {
    padding-left: 3rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-3 {
    padding-left: 0.75rem;
}
.pl-4 {
    padding-left: 1rem;
}
.pl-\[0px\] {
    padding-left: 0px;
}
.pl-\[20px\] {
    padding-left: 20px;
}
.pl-lg {
    padding-left: 16px;
}
.pl-md {
    padding-left: 12px;
}
.pl-xl {
    padding-left: 24px;
}
.pr-1\.5 {
    padding-right: 0.375rem;
}
.pr-2 {
    padding-right: 0.5rem;
}
.pr-7 {
    padding-right: 1.75rem;
}
.pr-\[0px\] {
    padding-right: 0px;
}
.pr-lg {
    padding-right: 16px;
}
.pr-md {
    padding-right: 12px;
}
.pr-sm {
    padding-right: var(--spacing-small);
}
.pt-0 {
    padding-top: 0px;
}
.pt-0\.5 {
    padding-top: 0.125rem;
}
.pt-10 {
    padding-top: 2.5rem;
}
.pt-11 {
    padding-top: 2.75rem;
}
.pt-12 {
    padding-top: 3rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-20 {
    padding-top: 5rem;
}
.pt-3xl {
    padding-top: 40px;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-40 {
    padding-top: 10rem;
}
.pt-7 {
    padding-top: 1.75rem;
}
.pt-8 {
    padding-top: 2rem;
}
.pt-\[100px\] {
    padding-top: 100px;
}
.pt-\[14px\] {
    padding-top: 14px;
}
.pt-\[3\.625rem\] {
    padding-top: 3.625rem;
}
.pt-\[5\.09px\] {
    padding-top: 5.09px;
}
.pt-\[56px\] {
    padding-top: 56px;
}
.pt-\[76px\] {
    padding-top: 76px;
}
.pt-md {
    padding-top: 12px;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.align-middle {
    vertical-align: middle;
}
.font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.font-montserrat {
    font-family: Montserrat, sans-serif;
}
.font-sans {
    font-family: PP Neue Montreal, sans-serif;
}
.font-serif {
    font-family: GT Super Display, serif;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-5xl {
    font-size: 3rem;
    line-height: 1;
}
.text-6xl {
    font-size: 3.75rem;
    line-height: 1;
}
.text-\[\.5rem\] {
    font-size: .5rem;
}
.text-\[0\.75rem\] {
    font-size: 0.75rem;
}
.text-\[0\.875rem\] {
    font-size: 0.875rem;
}
.text-\[10px\] {
    font-size: 10px;
}
.text-\[120px\] {
    font-size: 120px;
}
.text-\[12px\] {
    font-size: 12px;
}
.text-\[14px\] {
    font-size: 14px;
}
.text-\[15\.273px\] {
    font-size: 15.273px;
}
.text-\[16px\] {
    font-size: 16px;
}
.text-\[18px\] {
    font-size: 18px;
}
.text-\[1rem\] {
    font-size: 1rem;
}
.text-\[20px\] {
    font-size: 20px;
}
.text-\[24px\] {
    font-size: 24px;
}
.text-\[28px\] {
    font-size: 28px;
}
.text-\[36px\] {
    font-size: 36px;
}
.text-\[44px\] {
    font-size: 44px;
}
.text-\[64px\] {
    font-size: 64px;
}
.text-\[80px\] {
    font-size: 80px;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-\[500\] {
    font-weight: 500;
}
.font-bold {
    font-weight: 700;
}
.font-light {
    font-weight: 300;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.font-semibold {
    font-weight: 600;
}
.capitalize {
    text-transform: capitalize;
}
.italic {
    font-style: italic;
}
.leading-8 {
    line-height: 2rem;
}
.leading-\[\.9\] {
    line-height: .9;
}
.leading-\[1\.1\] {
    line-height: 1.1;
}
.leading-\[1\.25rem\] {
    line-height: 1.25rem;
}
.leading-\[1\] {
    line-height: 1;
}
.leading-\[22px\] {
    line-height: 22px;
}
.leading-\[28px\] {
    line-height: 28px;
}
.leading-\[34px\] {
    line-height: 34px;
}
.leading-\[72px\] {
    line-height: 72px;
}
.leading-tight {
    line-height: 1.25;
}
.tracking-\[-0\.32px\] {
    letter-spacing: -0.32px;
}
.tracking-\[-1\.4px\] {
    letter-spacing: -1.4px;
}
.tracking-\[0\.035rem\] {
    letter-spacing: 0.035rem;
}
.tracking-tight {
    letter-spacing: -0.025em;
}
.tracking-tighter {
    letter-spacing: -0.05em;
}
.tracking-wide {
    letter-spacing: 0.025em;
}
.tracking-widest {
    letter-spacing: 0.1em;
}
.\!text-cn-primary {
    --tw-text-opacity: 1 !important;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1)) !important;
}
.\!text-inherit {
    color: inherit !important;
}
.text-\[\#1F7045\] {
    --tw-text-opacity: 1;
    color: rgb(31 112 69 / var(--tw-text-opacity, 1));
}
.text-\[\#3D4D1E\] {
    --tw-text-opacity: 1;
    color: rgb(61 77 30 / var(--tw-text-opacity, 1));
}
.text-\[\#582D76\] {
    --tw-text-opacity: 1;
    color: rgb(88 45 118 / var(--tw-text-opacity, 1));
}
.text-\[\#709D45\] {
    --tw-text-opacity: 1;
    color: rgb(112 157 69 / var(--tw-text-opacity, 1));
}
.text-bg-accent {
    color: var(--color-blue750);
}
.text-bg-primary {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}
.text-blue-800 {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}
.text-cn-accent {
    color: var(--color-blue750);
}
.text-cn-disabled {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.text-cn-lightAccent {
    color: var(--color-blue200);
}
.text-cn-lightGray {
    --tw-text-opacity: 1;
    color: rgb(148 157 178 / var(--tw-text-opacity, 1));
}
.text-cn-negative {
    --tw-text-opacity: 1;
    color: rgb(228 79 82 / var(--tw-text-opacity, 1));
}
.text-cn-onColor {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.text-cn-positive {
    --tw-text-opacity: 1;
    color: rgb(70 100 45 / var(--tw-text-opacity, 1));
}
.text-cn-primary {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.text-cn-secondary {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
.text-cn-tertiary {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.text-green-800 {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}
.text-primary {
    --tw-text-opacity: 1;
    color: rgb(8 74 233 / var(--tw-text-opacity, 1));
}
.text-transparent {
    color: transparent;
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.underline {
    text-decoration-line: underline;
}
.line-through {
    text-decoration-line: line-through;
}
.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}
.placeholder-cn-tertiary::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(107 114 128 / var(--tw-placeholder-opacity, 1));
}
.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}
.opacity-20 {
    opacity: 0.2;
}
.opacity-30 {
    opacity: 0.3;
}
.opacity-5 {
    opacity: 0.05;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-70 {
    opacity: 0.7;
}
.mix-blend-overlay {
    mix-blend-mode: overlay;
}
.mix-blend-difference {
    mix-blend-mode: difference;
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_0_10px_0_rgba\(0\2c 0\2c 0\2c 0\.1\)\] {
    --tw-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    --tw-shadow-colored: 0 0 10px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_0px_32px_0px_rgba\(8\2c 75\2c 231\2c 0\.20\)\2c _0px_4px_48px_0px_rgba\(0\2c 0\2c 0\2c 0\.10\)\] {
    --tw-shadow: 0px 0px 32px 0px rgba(8,75,231,0.20), 0px 4px 48px 0px rgba(0,0,0,0.10);
    --tw-shadow-colored: 0px 0px 32px 0px var(--tw-shadow-color), 0px 4px 48px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_2\.223px_8\.892px_0px_rgba\(0\2c 0\2c 0\2c 0\.12\)\] {
    --tw-shadow: 0px 2.223px 8.892px 0px rgba(0,0,0,0.12);
    --tw-shadow-colored: 0px 2.223px 8.892px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_24px_60px_0px_rgba\(0\2c 0\2c 0\2c 0\.16\)\] {
    --tw-shadow: 0px 24px 60px 0px rgba(0,0,0,0.16);
    --tw-shadow-colored: 0px 24px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_4px_16px_0px_rgba\(0\2c 0\2c 0\2c 0\.12\)\] {
    --tw-shadow: 0px 4px 16px 0px rgba(0,0,0,0.12);
    --tw-shadow-colored: 0px 4px 16px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_4px_48px_0px_rgba\(0\2c 0\2c 0\2c 0\.10\)\] {
    --tw-shadow: 0px 4px 48px 0px rgba(0,0,0,0.10);
    --tw-shadow-colored: 0px 4px 48px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_6px_48px_0px_rgba\(0\2c 0\2c 0\2c 0\.16\)\] {
    --tw-shadow: 0px 6px 48px 0px rgba(0,0,0,0.16);
    --tw-shadow-colored: 0px 6px 48px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-deepBelow {
    --tw-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.22);
    --tw-shadow-colored: 0px 16px 48px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-modal {
    --tw-shadow: 0px 16px 48px rgba(0, 0, 0, 0.22);
    --tw-shadow-colored: 0px 16px 48px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-shallowBelow {
    --tw-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    --tw-shadow-colored: 0px 4px 16px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[1px\] {
    --tw-blur: blur(1px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.brightness-75 {
    --tw-brightness: brightness(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-none {
    --tw-backdrop-blur:  ;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.duration-300 {
    transition-duration: 300ms;
}
.duration-500 {
    transition-duration: 500ms;
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
  }
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

/* Apply font smoothing globally */
html,
body {
  -webkit-font-smoothing: antialiased;
  /* For Webkit browsers (Chrome, Safari) */
  -moz-osx-font-smoothing: grayscale;
  /* For Firefox on macOS */
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
}

/*
    Hides scrollbars on certain browsers
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/

/* IMAGE CROPPER STYLES */

.cropper-container {
  overflow: hidden;
  border-radius: 8px;
}

.cropper-hidden {
  display: none !important;
}

.cropper-invisible {
  opacity: 0;
}

/* Crop box */
.cropper-crop-box {
  border: 4px solid white;
  /* Thicker white border */
  border-radius: 16px;
  /* More pronounced rounded corners */
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
  /* Subtle shadow */
}

/* Overlay surrounding crop box */
.cropper-modal {
  background-color: gray !important;
}

/* Drag box */
.cropper-drag-box {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: black;
}

/* Drag handles styling */
.cropper-point {
  opacity: 0 !important;
  background-color: black !important;
  /* White drag handles */
  width: 12px;
  /* Adjust size */
  height: 12px;
  /* Adjust size */
  border-radius: 50%;
  /* Circular drag handles */
}

.cropper-view-box {
  border: 0px;
  outline: none !important;
  outline-width: 0px;
  border-radius: 10px;
  border-color: transparent;
  outline-color: transparent;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
  /* Adds a subtle shadow inside the crop box */
}

.cropper-line {
  background-color: white !important;
  border: none;
  background: none !important;
}

.cropper-canvas {
  filter: blur(4px);
  /*Apply blur effect to background*/
}

@media (min-width: 640px) {
    .sm\:display-xs {
        font-size: var(--fontSize-5xl);
        line-height: var(--lineHeight-4xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
    }
    .sm\:display-sm {
        font-size: var(--fontSize-7xl);
        line-height: var(--lineHeight-6xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
        letter-spacing: -1.76px;
    }
    .sm\:display-md {
        font-size: var(--fontSize-8xl);
        line-height: var(--lineHeight-7xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
        letter-spacing: -2.24px;
    }
    .sm\:heading-sans-sm {
        font-size: var(--fontSize-2xl);
        line-height: var(--lineHeight-xl);
        font-family: var(--font-sans);
    }
    .sm\:heading-serif-md {
        font-size: var(--fontSize-3xl);
        line-height: var(--lineHeight-2xl);
        font-family: var(--font-serif);
    }
    .sm\:heading-serif-xl {
        font-size: var(--fontSize-5xl);
        line-height: var(--lineHeight-4xl);
        font-family: var(--font-serif);
    }
    .sm\:label-md {
        font-size: var(--fontSize-md);
        line-height: var(--lineHeight-sm);
        font-family: var(--font-sans);
        font-weight: var(--fontWeight-medium);
    }
    .sm\:label-lg {
        font-size: var(--fontSize-lg);
        line-height: var(--lineHeight-md);
        font-family: var(--font-sans);
        font-weight: var(--fontWeight-medium);
    }
    .sm\:para-md {
        font-size: var(--fontSize-md);
        line-height: var(--lineHeight-sm);
        font-family: var(--font-sans);
        font-weight: var(--fontWeight-normal);
    }
    .sm\:para-lg {
        font-size: var(--fontSize-lg);
        line-height: var(--lineHeight-md);
        font-family: var(--font-sans);
        font-weight: var(--fontWeight-normal);
    }
}

@media (min-width: 768px) {
    .md\:display-xs {
        font-size: var(--fontSize-5xl);
        line-height: var(--lineHeight-4xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
    }
    .md\:display-sm {
        font-size: var(--fontSize-7xl);
        line-height: var(--lineHeight-6xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
        letter-spacing: -1.76px;
    }
    .md\:display-md {
        font-size: var(--fontSize-8xl);
        line-height: var(--lineHeight-7xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
        letter-spacing: -2.24px;
    }
    .md\:display-lg {
        font-size: var(--fontSize-9xl);
        line-height: var(--lineHeight-8xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
        letter-spacing: -3.84px;
    }
    .md\:heading-sans-xs {
        font-size: var(--fontSize-xl);
        line-height: var(--lineHeight-lg);
        font-family: var(--font-sans);
    }
    .md\:heading-sans-md {
        font-size: var(--fontSize-3xl);
        line-height: var(--lineHeight-2xl);
        font-family: var(--font-sans);
    }
    .md\:heading-serif-md {
        font-size: var(--fontSize-3xl);
        line-height: var(--lineHeight-2xl);
        font-family: var(--font-serif);
    }
    .md\:para-lg {
        font-size: var(--fontSize-lg);
        line-height: var(--lineHeight-md);
        font-family: var(--font-sans);
        font-weight: var(--fontWeight-normal);
    }
}

@media (min-width: 1024px) {
    .lg\:display-xs {
        font-size: var(--fontSize-5xl);
        line-height: var(--lineHeight-4xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
    }
    .lg\:display-lg {
        font-size: var(--fontSize-9xl);
        line-height: var(--lineHeight-8xl);
        font-family: var(--font-serif);
        font-weight: var(--fontWeight-light);
        letter-spacing: -3.84px;
    }
    .lg\:heading-sans-sm {
        font-size: var(--fontSize-2xl);
        line-height: var(--lineHeight-xl);
        font-family: var(--font-sans);
    }
    .lg\:heading-sans-2xl {
        font-size: var(--fontSize-6xl);
        line-height: var(--lineHeight-6xl);
        font-family: var(--font-sans);
    }
    .lg\:heading-serif-lg {
        font-size: var(--fontSize-4xl);
        line-height: var(--lineHeight-3xl);
        font-family: var(--font-serif);
    }
    .lg\:label-md {
        font-size: var(--fontSize-md);
        line-height: var(--lineHeight-sm);
        font-family: var(--font-sans);
        font-weight: var(--fontWeight-medium);
    }
    .lg\:label-2xl {
        font-size: var(--fontSize-2xl);
        line-height: var(--lineHeight-xl);
        font-family: var(--font-sans);
        font-weight: var(--fontWeight-medium);
    }
}

.\[\&\>div\]\:label-xs>div {
    font-size: var(--fontSize-xs);
    line-height: var(--lineHeight-xs);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-medium);
}

.\[\&\>p\>strong\]\:label-md>p>strong {
    font-size: var(--fontSize-md);
    line-height: var(--lineHeight-sm);
    font-family: var(--font-sans);
    font-weight: var(--fontWeight-medium);
}

.placeholder\:font-mono::placeholder {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.placeholder-shown\:truncate:placeholder-shown {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.focus-within\:bg-bg-primary:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.focus-within\:bg-transparent:focus-within {
    background-color: transparent;
}

.focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-\[1\.1\]:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-\[102\%\]:hover {
    --tw-scale-x: 102%;
    --tw-scale-y: 102%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-pointer:hover {
    cursor: pointer;
}

.hover\:bg-bg-tertiary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity, 1));
}

.hover\:bg-overlay-inverse100:hover {
    background-color: var(--Overlay-Inverse100);
}

.hover\:bg-overlay-inverse200:hover {
    background-color: var(--Overlay-Inverse200);
}

.hover\:font-medium:hover {
    font-weight: 500;
}

.hover\:opacity-100:hover {
    opacity: 1;
}

.hover\:shadow-\[0px_6px_48px_0px_rgba\(0\2c 0\2c 0\2c 0\.16\)\]:hover {
    --tw-shadow: 0px 6px 48px 0px rgba(0,0,0,0.16);
    --tw-shadow-colored: 0px 6px 48px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:\!border-transparent:focus {
    border-color: transparent !important;
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.disabled\:bg-bg-tertiary:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity, 1));
}

.disabled\:text-overlay-inverse200:disabled {
    color: var(--Overlay-Inverse200);
}

.group:hover .group-hover\:ml-2 {
    margin-left: 0.5rem;
}

.group:hover .group-hover\:block {
    display: block;
}

.group:hover .group-hover\:max-w-\[120px\] {
    max-width: 120px;
}

.group:hover .group-hover\:bg-\[linear-gradient\(90deg\2c _rgba\(255\2c 255\2c 255\2c 0\.08\)_0\%\2c _rgba\(0\2c 0\2c 0\2c 0\.08\)_29\%\2c _rgba\(0\2c 0\2c 0\2c 0\.08\)_70\.5\%\2c rgba\(255\2c 255\2c 255\2c 0\.08\)_100\%\)\] {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.08) 0%, rgba(0,0,0,0.08) 29%, rgba(0,0,0,0.08) 70.5%,rgba(255,255,255,0.08) 100%);
}

.group:hover .group-hover\:opacity-100 {
    opacity: 1;
}

.data-\[state\=open\]\:bg-bg-tertiary[data-state="open"] {
    --tw-bg-opacity: 1;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity, 1));
}

@media (min-width: 640px) {
    .sm\:visible {
        visibility: visible;
    }
    .sm\:absolute {
        position: absolute;
    }
    .sm\:relative {
        position: relative;
    }
    .sm\:bottom-3 {
        bottom: 0.75rem;
    }
    .sm\:left-10 {
        left: 2.5rem;
    }
    .sm\:left-3 {
        left: 0.75rem;
    }
    .sm\:left-8 {
        left: 2rem;
    }
    .sm\:top-5 {
        top: 1.25rem;
    }
    .sm\:top-8 {
        top: 2rem;
    }
    .sm\:top-\[20\%\] {
        top: 20%;
    }
    .sm\:mx-4 {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .sm\:mx-40 {
        margin-left: 10rem;
        margin-right: 10rem;
    }
    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .sm\:my-9 {
        margin-top: 2.25rem;
        margin-bottom: 2.25rem;
    }
    .sm\:mb-0 {
        margin-bottom: 0px;
    }
    .sm\:mb-2xl {
        margin-bottom: 32px;
    }
    .sm\:ml-3 {
        margin-left: 0.75rem;
    }
    .sm\:ml-auto {
        margin-left: auto;
    }
    .sm\:mr-2 {
        margin-right: 0.5rem;
    }
    .sm\:mt-0 {
        margin-top: 0px;
    }
    .sm\:mt-10 {
        margin-top: 2.5rem;
    }
    .sm\:mt-4 {
        margin-top: 1rem;
    }
    .sm\:mt-6 {
        margin-top: 1.5rem;
    }
    .sm\:mt-9 {
        margin-top: 2.25rem;
    }
    .sm\:mt-\[14rem\] {
        margin-top: 14rem;
    }
    .sm\:block {
        display: block;
    }
    .sm\:flex {
        display: flex;
    }
    .sm\:hidden {
        display: none;
    }
    .sm\:h-5 {
        height: 1.25rem;
    }
    .sm\:h-6 {
        height: 1.5rem;
    }
    .sm\:h-\[157px\] {
        height: 157px;
    }
    .sm\:h-\[243px\] {
        height: 243px;
    }
    .sm\:h-\[300px\] {
        height: 300px;
    }
    .sm\:h-\[320px\] {
        height: 320px;
    }
    .sm\:h-\[327px\] {
        height: 327px;
    }
    .sm\:h-\[350px\] {
        height: 350px;
    }
    .sm\:h-\[4\.125rem\] {
        height: 4.125rem;
    }
    .sm\:h-\[64px\] {
        height: 64px;
    }
    .sm\:h-fit {
        height: fit-content;
    }
    .sm\:h-full {
        height: 100%;
    }
    .sm\:max-h-\[200px\] {
        max-height: 200px;
    }
    .sm\:max-h-\[80\%\] {
        max-height: 80%;
    }
    .sm\:min-h-\[327px\] {
        min-height: 327px;
    }
    .sm\:min-h-\[400px\] {
        min-height: 400px;
    }
    .sm\:min-h-\[500px\] {
        min-height: 500px;
    }
    .sm\:min-h-\[calc\(100vh_-_100px\)\] {
        min-height: calc(100vh - 100px);
    }
    .sm\:min-h-full {
        min-height: 100%;
    }
    .sm\:w-6 {
        width: 1.5rem;
    }
    .sm\:w-\[136px\] {
        width: 136px;
    }
    .sm\:w-\[210px\] {
        width: 210px;
    }
    .sm\:w-\[213px\] {
        width: 213px;
    }
    .sm\:w-\[250px\] {
        width: 250px;
    }
    .sm\:w-\[300px\] {
        width: 300px;
    }
    .sm\:w-\[303px\] {
        width: 303px;
    }
    .sm\:w-\[320px\] {
        width: 320px;
    }
    .sm\:w-\[520px\] {
        width: 520px;
    }
    .sm\:w-\[unset\] {
        width: unset;
    }
    .sm\:w-auto {
        width: auto;
    }
    .sm\:w-fit {
        width: fit-content;
    }
    .sm\:w-full {
        width: 100%;
    }
    .sm\:min-w-\[185px\] {
        min-width: 185px;
    }
    .sm\:min-w-\[500px\] {
        min-width: 500px;
    }
    .sm\:min-w-\[600px\] {
        min-width: 600px;
    }
    .sm\:min-w-\[700px\] {
        min-width: 700px;
    }
    .sm\:max-w-\[310px\] {
        max-width: 310px;
    }
    .sm\:max-w-\[400px\] {
        max-width: 400px;
    }
    .sm\:max-w-\[50\%\] {
        max-width: 50%;
    }
    .sm\:max-w-\[500px\] {
        max-width: 500px;
    }
    .sm\:max-w-\[508px\] {
        max-width: 508px;
    }
    .sm\:max-w-\[600px\] {
        max-width: 600px;
    }
    .sm\:max-w-\[750px\] {
        max-width: 750px;
    }
    .sm\:max-w-\[800px\] {
        max-width: 800px;
    }
    .sm\:max-w-\[unset\] {
        max-width: unset;
    }
    .sm\:max-w-full {
        max-width: 100%;
    }
    .sm\:max-w-max {
        max-width: max-content;
    }
    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .sm\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .sm\:flex-row {
        flex-direction: row;
    }
    .sm\:flex-col {
        flex-direction: column;
    }
    .sm\:items-start {
        align-items: flex-start;
    }
    .sm\:justify-normal {
        justify-content: normal;
    }
    .sm\:justify-start {
        justify-content: flex-start;
    }
    .sm\:justify-center {
        justify-content: center;
    }
    .sm\:gap-0 {
        gap: 0px;
    }
    .sm\:gap-10 {
        gap: 2.5rem;
    }
    .sm\:gap-16 {
        gap: 4rem;
    }
    .sm\:gap-20 {
        gap: 5rem;
    }
    .sm\:gap-3 {
        gap: 0.75rem;
    }
    .sm\:gap-3xl {
        gap: 40px;
    }
    .sm\:gap-6 {
        gap: 1.5rem;
    }
    .sm\:gap-8 {
        gap: 2rem;
    }
    .sm\:gap-9 {
        gap: 2.25rem;
    }
    .sm\:gap-\[4rem\] {
        gap: 4rem;
    }
    .sm\:gap-md {
        gap: 12px;
    }
    .sm\:gap-xs {
        gap: var(--spacing-xsmall);
    }
    .sm\:gap-x-6 {
        column-gap: 1.5rem;
    }
    .sm\:gap-y-10 {
        row-gap: 2.5rem;
    }
    .sm\:overflow-y-auto {
        overflow-y: auto;
    }
    .sm\:p-0 {
        padding: 0px;
    }
    .sm\:p-10 {
        padding: 2.5rem;
    }
    .sm\:p-3xl {
        padding: 40px;
    }
    .sm\:p-6 {
        padding: 1.5rem;
    }
    .sm\:p-8 {
        padding: 2rem;
    }
    .sm\:p-xl {
        padding: 24px;
    }
    .sm\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .sm\:px-12 {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .sm\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .sm\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .sm\:py-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .sm\:py-3 {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    .sm\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .sm\:py-\[120px\] {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .sm\:py-\[3\.75rem\] {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
    }
    .sm\:pb-20 {
        padding-bottom: 5rem;
    }
    .sm\:pb-sm {
        padding-bottom: var(--spacing-small);
    }
    .sm\:pr-0 {
        padding-right: 0px;
    }
    .sm\:pr-\[60px\] {
        padding-right: 60px;
    }
    .sm\:pt-0 {
        padding-top: 0px;
    }
    .sm\:pt-2 {
        padding-top: 0.5rem;
    }
    .sm\:pt-3 {
        padding-top: 0.75rem;
    }
    .sm\:pt-8 {
        padding-top: 2rem;
    }
    .sm\:pt-md {
        padding-top: 12px;
    }
    .sm\:pt-xl {
        padding-top: 24px;
    }
    .sm\:text-center {
        text-align: center;
    }
    .sm\:text-\[1\.2rem\] {
        font-size: 1.2rem;
    }
    .sm\:text-\[4\.5rem\] {
        font-size: 4.5rem;
    }
    .sm\:text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .sm\:leading-\[4\.5rem\] {
        line-height: 4.5rem;
    }
    .sm\:text-cn-lightAccent {
        color: var(--color-blue200);
    }
    .sm\:shadow-lg {
        --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .sm\:shadow-none {
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .sm\:shadow-shallowBelow {
        --tw-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
        --tw-shadow-colored: 0px 4px 16px 0px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .sm\:backdrop-blur-sm {
        --tw-backdrop-blur: blur(4px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    }
}

@media (min-width: 768px) {
    .md\:absolute {
        position: absolute;
    }
    .md\:relative {
        position: relative;
    }
    .md\:bottom-12 {
        bottom: 3rem;
    }
    .md\:bottom-8 {
        bottom: 2rem;
    }
    .md\:left-0 {
        left: 0px;
    }
    .md\:left-12 {
        left: 3rem;
    }
    .md\:right-12 {
        right: 3rem;
    }
    .md\:right-20 {
        right: 5rem;
    }
    .md\:top-\[1\.5rem\] {
        top: 1.5rem;
    }
    .md\:top-\[74px\] {
        top: 74px;
    }
    .md\:ml-\[80px\] {
        margin-left: 80px;
    }
    .md\:mt-3xl {
        margin-top: 40px;
    }
    .md\:mt-\[29px\] {
        margin-top: 29px;
    }
    .md\:block {
        display: block;
    }
    .md\:inline-block {
        display: inline-block;
    }
    .md\:inline {
        display: inline;
    }
    .md\:flex {
        display: flex;
    }
    .md\:hidden {
        display: none;
    }
    .md\:h-72 {
        height: 18rem;
    }
    .md\:h-\[156px\] {
        height: 156px;
    }
    .md\:h-\[300px\] {
        height: 300px;
    }
    .md\:h-\[368px\] {
        height: 368px;
    }
    .md\:h-\[400px\] {
        height: 400px;
    }
    .md\:h-\[558px\] {
        height: 558px;
    }
    .md\:h-\[74px\] {
        height: 74px;
    }
    .md\:h-\[unset\] {
        height: unset;
    }
    .md\:h-full {
        height: 100%;
    }
    .md\:h-screen {
        height: 100vh;
    }
    .md\:max-h-\[521px\] {
        max-height: 521px;
    }
    .md\:max-h-\[750px\] {
        max-height: 750px;
    }
    .md\:w-72 {
        width: 18rem;
    }
    .md\:w-\[156px\] {
        width: 156px;
    }
    .md\:w-\[345px\] {
        width: 345px;
    }
    .md\:w-\[400px\] {
        width: 400px;
    }
    .md\:w-\[450px\] {
        width: 450px;
    }
    .md\:w-\[60vw\] {
        width: 60vw;
    }
    .md\:w-\[712px\] {
        width: 712px;
    }
    .md\:w-\[unset\] {
        width: unset;
    }
    .md\:w-auto {
        width: auto;
    }
    .md\:w-full {
        width: 100%;
    }
    .md\:max-w-\[366px\] {
        max-width: 366px;
    }
    .md\:max-w-\[480px\] {
        max-width: 480px;
    }
    .md\:max-w-\[569px\] {
        max-width: 569px;
    }
    .md\:max-w-\[598\.5px\] {
        max-width: 598.5px;
    }
    .md\:max-w-\[620px\] {
        max-width: 620px;
    }
    .md\:max-w-\[654px\] {
        max-width: 654px;
    }
    .md\:max-w-\[750px\] {
        max-width: 750px;
    }
    .md\:max-w-full {
        max-width: 100%;
    }
    .md\:flex-1 {
        flex: 1 1 0%;
    }
    .md\:flex-row {
        flex-direction: row;
    }
    .md\:flex-row-reverse {
        flex-direction: row-reverse;
    }
    .md\:flex-col {
        flex-direction: column;
    }
    .md\:items-start {
        align-items: flex-start;
    }
    .md\:items-center {
        align-items: center;
    }
    .md\:gap-10 {
        gap: 2.5rem;
    }
    .md\:gap-2 {
        gap: 0.5rem;
    }
    .md\:gap-5 {
        gap: 1.25rem;
    }
    .md\:gap-60 {
        gap: 15rem;
    }
    .md\:gap-\[50px\] {
        gap: 50px;
    }
    .md\:gap-xl {
        gap: 24px;
    }
    .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1rem * var(--tw-space-y-reverse));
    }
    .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(2rem * var(--tw-space-y-reverse));
    }
    .md\:rounded-\[20px\] {
        border-radius: 20px;
    }
    .md\:rounded-full {
        border-radius: 9999px;
    }
    .md\:rounded-lg {
        border-radius: var(--radiiLarge-container);
    }
    .md\:rounded-sm {
        border-radius: var(--radiiSmallContainer);
    }
    .md\:border-b {
        border-bottom-width: 1px;
    }
    .md\:bg-bg-primary {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    }
    .md\:p-10 {
        padding: 2.5rem;
    }
    .md\:p-12 {
        padding: 3rem;
    }
    .md\:p-16 {
        padding: 4rem;
    }
    .md\:p-lg {
        padding: 16px;
    }
    .md\:p-xl {
        padding: 24px;
    }
    .md\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .md\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .md\:px-3 {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    .md\:px-lg {
        padding-left: 16px;
        padding-right: 16px;
    }
    .md\:px-md {
        padding-left: 12px;
        padding-right: 12px;
    }
    .md\:py-1 {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    .md\:py-48 {
        padding-top: 12rem;
        padding-bottom: 12rem;
    }
    .md\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .md\:py-\[270px\] {
        padding-top: 270px;
        padding-bottom: 270px;
    }
    .md\:py-sm {
        padding-top: var(--spacing-small);
        padding-bottom: var(--spacing-small);
    }
    .md\:pb-\[200px\] {
        padding-bottom: 200px;
    }
    .md\:pl-xl {
        padding-left: 24px;
    }
    .md\:pr-16 {
        padding-right: 4rem;
    }
    .md\:pt-3xl {
        padding-top: 40px;
    }
    .md\:pt-\[160px\] {
        padding-top: 160px;
    }
    .md\:text-left {
        text-align: left;
    }
    .md\:text-\[100px\] {
        font-size: 100px;
    }
    .md\:text-\[16px\] {
        font-size: 16px;
    }
    .md\:text-\[200px\] {
        font-size: 200px;
    }
    .md\:text-\[6\.5rem\] {
        font-size: 6.5rem;
    }
    .md\:text-\[64px\] {
        font-size: 64px;
    }
    .md\:text-\[70px\] {
        font-size: 70px;
    }
    .md\:text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .md\:font-\[3\.75rem\] {
        font-weight: 3.75rem;
    }
    .md\:leading-\[6\.5rem\] {
        line-height: 6.5rem;
    }
    .group:hover .md\:group-hover\:opacity-100 {
        opacity: 1;
    }
}

@media (min-width: 1024px) {
    .lg\:fixed {
        position: fixed;
    }
    .lg\:ml-3xl {
        margin-left: 40px;
    }
    .lg\:ml-auto {
        margin-left: auto;
    }
    .lg\:mt-10 {
        margin-top: 2.5rem;
    }
    .lg\:mt-\[5\.5rem\] {
        margin-top: 5.5rem;
    }
    .lg\:block {
        display: block;
    }
    .lg\:flex {
        display: flex;
    }
    .lg\:grid {
        display: grid;
    }
    .lg\:hidden {
        display: none;
    }
    .lg\:h-\[270px\] {
        height: 270px;
    }
    .lg\:h-\[500px\] {
        height: 500px;
    }
    .lg\:h-screen {
        height: 100vh;
    }
    .lg\:max-h-\[400px\] {
        max-height: 400px;
    }
    .lg\:min-h-\[480px\] {
        min-height: 480px;
    }
    .lg\:w-60 {
        width: 15rem;
    }
    .lg\:w-\[100px\] {
        width: 100px;
    }
    .lg\:w-\[500px\] {
        width: 500px;
    }
    .lg\:w-\[650px\] {
        width: 650px;
    }
    .lg\:w-auto {
        width: auto;
    }
    .lg\:w-full {
        width: 100%;
    }
    .lg\:min-w-\[650px\] {
        min-width: 650px;
    }
    .lg\:min-w-\[800px\] {
        min-width: 800px;
    }
    .lg\:min-w-\[905px\] {
        min-width: 905px;
    }
    .lg\:max-w-\[335px\] {
        max-width: 335px;
    }
    .lg\:max-w-\[905px\] {
        max-width: 905px;
    }
    .lg\:max-w-\[unset\] {
        max-width: unset;
    }
    .lg\:max-w-none {
        max-width: none;
    }
    .lg\:grid-flow-col {
        grid-auto-flow: column;
    }
    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .lg\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .lg\:flex-row {
        flex-direction: row;
    }
    .lg\:justify-between {
        justify-content: space-between;
    }
    .lg\:gap-0 {
        gap: 0px;
    }
    .lg\:gap-\[70px\] {
        gap: 70px;
    }
    .lg\:overflow-visible {
        overflow: visible;
    }
    .lg\:overflow-scroll {
        overflow: scroll;
    }
    .lg\:p-xl {
        padding: 24px;
    }
    .lg\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .lg\:px-3xl {
        padding-left: 40px;
        padding-right: 40px;
    }
    .lg\:pl-3xl {
        padding-left: 40px;
    }
    .lg\:pr-\[60px\] {
        padding-right: 60px;
    }
    .lg\:pt-0 {
        padding-top: 0px;
    }
    .lg\:pt-4 {
        padding-top: 1rem;
    }
    .lg\:text-6xl {
        font-size: 3.75rem;
        line-height: 1;
    }
    .lg\:text-\[16px\] {
        font-size: 16px;
    }
    .lg\:text-\[22px\] {
        font-size: 22px;
    }
    .lg\:text-\[5rem\] {
        font-size: 5rem;
    }
    .lg\:text-\[7\.5rem\] {
        font-size: 7.5rem;
    }
    .lg\:leading-\[110\%\] {
        line-height: 110%;
    }
    .lg\:leading-\[7\.5rem\] {
        line-height: 7.5rem;
    }
    .lg\:-tracking-\[3\.2px\] {
        letter-spacing: -3.2px;
    }
}

@media (min-width: 1280px) {
    .xl\:mx-40 {
        margin-left: 10rem;
        margin-right: 10rem;
    }
    .xl\:block {
        display: block;
    }
    .xl\:flex {
        display: flex;
    }
    .xl\:grid {
        display: grid;
    }
    .xl\:h-\[230px\] {
        height: 230px;
    }
    .xl\:h-\[315px\] {
        height: 315px;
    }
    .xl\:w-\[140px\] {
        width: 140px;
    }
    .xl\:w-\[150px\] {
        width: 150px;
    }
    .xl\:w-full {
        width: 100%;
    }
    .xl\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .xl\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .xl\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .xl\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .xl\:grid-cols-\[66px_1fr_100px\] {
        grid-template-columns: 66px 1fr 100px;
    }
    .xl\:grid-rows-1 {
        grid-template-rows: repeat(1, minmax(0, 1fr));
    }
    .xl\:flex-row {
        flex-direction: row;
    }
    .xl\:justify-end {
        justify-content: flex-end;
    }
    .xl\:gap-3 {
        gap: 0.75rem;
    }
    .xl\:px-3xl {
        padding-left: 40px;
        padding-right: 40px;
    }
    .xl\:pb-xl {
        padding-bottom: 24px;
    }
}

.\[\&\>div\>svg\>path\]\:stroke-white>div>svg>path {
    stroke: #fff;
}

.\[\&\>div\]\:flex>div {
    display: flex;
}

.\[\&\>div\]\:w-full>div {
    width: 100%;
}

.\[\&\>div\]\:justify-center>div {
    justify-content: center;
}

.\[\&\>div\]\:truncate>div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.\[\&\>div\]\:text-bg-accent>div {
    color: var(--color-blue750);
}

.\[\&\>div\]\:text-cn-primary>div {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.\[\&\>ol\>li\>p\]\:inline>ol>li>p {
    display: inline;
}

.\[\&\>ol\]\:list-inside>ol {
    list-style-position: inside;
}

.\[\&\>ol\]\:list-decimal>ol {
    list-style-type: decimal;
}

.\[\&\>p\>a\]\:text-cn-accent>p>a {
    color: var(--color-blue750);
}

.\[\&\>p\>strong\]\:text-cn-primary>p>strong {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.\[\&\>path\]\:fill-white>path {
    fill: #fff;
}

.\[\&\>ul\]\:list-inside>ul {
    list-style-position: inside;
}

.\[\&\>ul\]\:list-disc>ul {
    list-style-type: disc;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}

